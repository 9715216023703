@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");

body {
  @apply bg-slate-200 font-body;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#menu-items li {
  @apply py-1.5 hover:text-main hover:cursor-pointer transition duration-75 ease-in;
}

#footer-items ul li {
  @apply py-1;
}

#footer-items ul li a {
  @apply hover:text-main hover:cursor-pointer transition duration-100;
}
.partner-logo {
  @apply lg:w-32 lg:h-32 md:w-20 md:h-20 w-14 h-14 grid items-center mx-auto;
}
.anchor {
  @apply hidden;
}
.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #b325e0; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
